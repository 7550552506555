import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { getTileValue } from '../helpers/getTileValue';
import { totalAttemptsSelector } from '../state/attempts';
import { tileMatchSelector } from '../state/match';
import { cursorColumnSelector } from '../state/keyboardInput';
import { gameIsFinishedSelector } from '../state/score';
import { COLORS } from '../constants/theme';

interface TitleBoxStyleProps {
  $close?: boolean;
  $filled?: boolean;
  $match?: boolean;
  $firstMatch?: boolean;
  $cursor?: boolean;
}

const TileBox = styled(Box)<TitleBoxStyleProps>`
  position: relative;
  width: 46px;
  height: 46px;
  border-color: ${COLORS.gray};
  color: ${COLORS.offWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  border-width: 3px;
  border-style: solid;

  @media (max-width: 374px) {
    width: 32px;
    height: 32px;
  }

  @media (min-height: 812px) {
    width: 56px;
    height: 56px;
  }

  ${({ $filled }) => $filled && `
    background-color: ${COLORS.gray};
  `}

  ${({ $close }) => $close && `
    background-color: ${COLORS.orange};
    border-color: ${COLORS.orange};
  `}

  ${({ $match }) => $match && `
    background-color: ${COLORS.green};
    border-color: ${COLORS.green};
  `}

  ${({ $firstMatch }) => $firstMatch && `
    border-color: ${COLORS.offWhite};
  `}

  ${({ $cursor }) => $cursor && `
    border-color: ${COLORS.offWhite};
    background-color: ${COLORS.gray};
    border-style: dashed;
  `}
`;

const TileLetter = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  margin-top: 2px;

  @media (max-width: 374px) {
    font-size: 18px;
  }

  @media (min-height: 812px) {
    font-size: 26px;
  }
`;

const TileValue = styled(Typography)`
  position: absolute;
  right: 3px;
  top: 2px;
  font-size: 11px;
  font-weight: 700;
  line-height: 1;

  @media (max-width: 374px) {
    font-size: 9px;
  }

  @media (min-height: 812px) {
    font-size: 13px;
  }
`;

interface TileProps {
  columnIndex?: number;
  letter?: string;
  rowIndex?: number;
}

const Tile = ({
  columnIndex,
  letter = '',
  rowIndex = 0,
}: TileProps): JSX.Element => {
  const totalAttempts = useRecoilValue(totalAttemptsSelector);
  const cursorColumn = useRecoilValue(cursorColumnSelector);
  const gameIsFinished = useRecoilValue(gameIsFinishedSelector);
  const {
    isClose,
    isFirstMatched,
    isMatched,
  } = useRecoilValue(
    tileMatchSelector([rowIndex || 0, columnIndex || 0])
  );

  const value = useMemo(() => (
    getTileValue({ letter, rowIndex })
  ), [letter, rowIndex]);

  const isCurrentAttempt = rowIndex === totalAttempts;
  const hasCursor = isCurrentAttempt && cursorColumn === columnIndex;

  return (
    <TileBox
      $close={isClose}
      $filled={!!letter}
      $match={isMatched}
      $firstMatch={isFirstMatched}
      $cursor={hasCursor && !gameIsFinished}
    >
      {value > 0 && (isCurrentAttempt || isFirstMatched) && (
        <TileValue>
          {value}
        </TileValue>
      )}
      <TileLetter>
        {letter}
      </TileLetter>
    </TileBox>
  );
};

export {
  Tile,
  TileBox,
  TileLetter,
  TileValue,
};
