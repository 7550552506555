import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { keyboardInputAtom } from '../state/keyboardInput';
import { attemptsAtom } from '../state/attempts';
import { ROW_COUNT } from '../constants/limits';
import { TilesRow } from './TilesRow';
import { TilesInputRow } from './TilesInputRow';
import { gameNumberAtom } from '../state/gameNumber';

const Tiles = (): JSX.Element => {
  const gameNumber = useRecoilValue(gameNumberAtom);
  const rows = useRecoilValue(attemptsAtom(gameNumber));
  const keyboardInput = useRecoilValue(keyboardInputAtom);

  const spaces = useMemo(() => Array(ROW_COUNT).fill(null), []);
  const lastPopulatedRow = rows.length;
  const inputLetters = keyboardInput.split('');

  return (
    <Box pt={1}>
      {spaces.map((_, i) => (
        <Box
          key={i}
          display="flex" 
          justifyContent="center"
        >
          {i === lastPopulatedRow ? (
            <TilesInputRow inputLetters={inputLetters} rowIndex={i} />
          ) : (
            <TilesRow row={rows[i] || ''} rowIndex={i} />
          )}
        </Box>
      ))}
    </Box>
  )  
};

export { Tiles };
