import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { produce } from 'immer';

import { attemptsAtom, usedLettersSelector } from '../state/attempts';
import { closeLettersSelector, matchedLettersSelector } from '../state/match';
import { Keyboard } from './Keyboard';
import { keyboardInputAtom } from '../state/keyboardInput';
import { gameIsFinishedSelector } from '../state/score';
import { COLUMN_COUNT } from '../constants/limits';
import { DICTIONARY } from '../constants/dictionary';
import { useKeyboardTyping } from '../hooks/useKeyboardTyping';
import { useFeedback } from '../hooks/useFeedback';
import { gameNumberAtom } from '../state/gameNumber';

const KeyboardWithInput = (): JSX.Element => {
  const { launchFeedback } = useFeedback();
  const [input, setInput] = useRecoilState(keyboardInputAtom);
  const gameNumber = useRecoilValue(gameNumberAtom);
  const [attempts, setAttempts] = useRecoilState(attemptsAtom(gameNumber));
  const usedLetters = useRecoilValue(usedLettersSelector);
  const closeLetters = useRecoilValue(closeLettersSelector);
  const matchedLetters = useRecoilValue(matchedLettersSelector);
  const gameIsFinished = useRecoilValue(gameIsFinishedSelector);

  const {
    canDelete,
    canEnter,
  } = useMemo(() => ({
    canDelete: input.length > 0,
    canEnter: input.length === COLUMN_COUNT,
  }), [input]);

  const onEnterPress = useCallback(() => {
    if (canEnter) {
      const isInDictionary = !!DICTIONARY.find((w) => w === input);
      if (isInDictionary) {
        setAttempts(produce(attempts, (newAttempts) => {
          newAttempts.push(input);
        }));
        gtag('event', 'word_valid', { 
          word: input,
        });
        setInput('');
      } else {
        gtag('event', 'word_invalid', { 
          word: input,
        });
        launchFeedback({
          mood: 'negative',
          message: `"${input}" is not a valid word`,
        })
      }
    }
  }, [attempts, canEnter, input, launchFeedback, setAttempts, setInput]);

  const onKeyPress = useCallback((key) => {
    if (input.length < COLUMN_COUNT) {
      setInput([input, key].join(''));
    }
  }, [setInput, input]);

  const onDeletePress = useCallback(() => (
    setInput(input.slice(0, -1))
  ), [setInput, input]);

  useKeyboardTyping({
    disabled: gameIsFinished,
    onEnterPress,
    onDeletePress,
    onKeyPress,
  });

  return (
    <Keyboard
      canDelete={canDelete}
      canEnter={canEnter}
      closeLetters={closeLetters}
      disabled={gameIsFinished}
      matchedLetters={matchedLetters}
      onKeyPress={onKeyPress}
      onDeletePress={onDeletePress}
      onEnterPress={onEnterPress}
      usedLetters={usedLetters}
    />
  );
};

export {
  keyboardInputAtom,
  KeyboardWithInput,
};
