import { useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { useRecoilValue, useRecoilState } from 'recoil';
import styled from 'styled-components';

import { Header } from './components/Header';
import { KeyboardWithInput } from './components/KeyboardWithInput';
import { Tiles } from './components/Tiles';
import { GameNumber } from './components/GameNumber';
import { Feedback } from './components/Feedback';
import { HelpDialog } from './components/HelpDialog';
import { StatsDialog } from './components/StatsDialog';
import { gameIsFinishedSelector } from './state/score';
import { dialogAtom } from './state/dialog';
import { useCompleteGame } from './hooks/useCompleteGame';

const MainScreenWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 170px);
  margin-top: 56px;
`;

const MainScreenContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const App = (): JSX.Element => {
  const gameIsFinished = useRecoilValue(gameIsFinishedSelector);
  const [dialog, setDialog] = useRecoilState(dialogAtom);

  useEffect(() => {
    if (dialog !== 'stats' && gameIsFinished) {
      setDialog('stats');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIsFinished]);

  useCompleteGame();

  return (
    <div className="App">
      <Header />
      <MainScreenWrapper>
        <Feedback />
        <MainScreenContainer maxWidth="xs">
          <GameNumber />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
          >
            <Tiles />
          </Box>
          <KeyboardWithInput />
        </MainScreenContainer>
      </MainScreenWrapper>
      <HelpDialog />
      <StatsDialog />
    </div>
  );
}

export default App;
