import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Button, Typography } from '@mui/material';
import { Share } from '@mui/icons-material';
import styled from 'styled-components';

import { shareOutputSelector } from '../state/share';
import { COLORS } from '../constants/theme';

const ShareButton = styled(Button)`
  font-weight: 600;
`;

const CopyText = styled(Typography)`
  display: inline-block;
  background-color: ${COLORS.green};
  color: ${COLORS.offWhite};
  padding: 2px 8px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 3px;
  margin-top: 8px;
  text-align: center;
`;

const StatsShare = (): JSX.Element => {
  const [copied, setCopied] = useState(false);
  const shareOutput = useRecoilValue(shareOutputSelector);

  const share = useCallback(() => {
    // @ts-ignore
    if (navigator.share) {
      gtag('event', 'share', {
        method: 'Navigator Share',
      });
      navigator.share({
        text: shareOutput,
      });
    } else {
      gtag('event', 'share', {
        method: 'Copy',
      });
      navigator.clipboard.writeText(shareOutput);
      setCopied(true);
    }
  }, [shareOutput]);

  useEffect(() => {
    const messageTimeout = setTimeout(() => {
      if (setCopied) {
        setCopied(false);
      }
    }, 3000);

    return () => {
      clearTimeout(messageTimeout);
    };
  }, [copied]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      pt={0.5}
    >
      <ShareButton
        variant="contained"
        color="primary"
        onClick={share}
        startIcon={<Share />}
      >
        Share
      </ShareButton>
      {copied && (
        <CopyText>
          Copied to clipboard
        </CopyText>
      )}
    </Box>
  );
};

export { StatsShare };
