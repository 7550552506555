import { atom, selectorFamily } from 'recoil';

const dialogAtom = atom<string | null>({
  key: 'modal',
  default: null,
});

const openDialogSelector = selectorFamily({
  key: 'openDialog',
  get: (dialog) => ({ get }) => get(dialogAtom) === dialog,
});

export {
  dialogAtom,
  openDialogSelector,
};
