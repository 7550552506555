import { useRecoilState, useSetRecoilState } from 'recoil';
import { Box, Button } from '@mui/material';

import { attemptsAtom } from '../state/attempts';
import { dialogAtom } from '../state/dialog';
import { gameNumberAtom } from '../state/gameNumber';

const DebugMenu = (): JSX.Element => {
  const [gameNumber, setGameNumber] = useRecoilState(gameNumberAtom);
  const setAttempts = useSetRecoilState(attemptsAtom(gameNumber));
  const setDialog = useSetRecoilState(dialogAtom);

  return (
    <Box display="flex" justifyContent="space-around" p={2}>
      <Button
        color="secondary"
        onClick={() => {
          setDialog(null);
          setAttempts([]);
          setGameNumber(gameNumber - 1);
        }}
      >
        Previous
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          setDialog(null);
          setAttempts([]);
          setGameNumber(gameNumber + 1);
        }}
      >
        Next
      </Button>
    </Box>
  );
};

export { DebugMenu };
