import { Box } from '@mui/material';

import { Tile } from './Tile';
import { COLUMN_COUNT } from '../constants/limits';

interface TilesRowProps {
  rowIndex: number;
  row: string;
}

const TilesRow = ({
  rowIndex,
  row,
}: TilesRowProps): JSX.Element => {
  const columns = row.split('');
  const hasColumns = columns.length > 0;
  
  return (
    <Box display="flex" justifyContent="center">
      {hasColumns ? columns.map((key, i) => (
        <Box key={`${key}__${i}`} p={0.5} flexShrink={0}>
          <Tile letter={key} rowIndex={rowIndex} columnIndex={i} />
        </Box>
      )) : Array(COLUMN_COUNT).fill(null).map((_, i) => (
        <Box key={i} p={0.5} flexShrink={0}>
          <Tile rowIndex={rowIndex} />
        </Box>
      ))}
    </Box>
  );
};

export { TilesRow };
