import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { columnSelector } from '../state/score';
import { TileBox, TileValue, TileLetter } from './Tile';
import { getMultiplierFromRow } from '../helpers/getMultiplierFromRow';
import { LETTER_VALUES } from '../constants/letterValues';

const AttemptWrapper = styled(Box)`
  ${TileBox} {
    width: 32px !important;
    height: 32px !important;
  }

  ${TileLetter} {
    font-size: 18px !important;
  }

  ${TileValue} {
    font-size: 8px !important;
    top: 1px;
    right: 1px;
  }
`;

const ProductLabel = styled(Typography)`
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin-top: 6px;

  strong {
    font-weight: 700;
  }
`;

const AttemptTiles = (): JSX.Element => {
  const column1 = useRecoilValue(columnSelector(0));
  const column2 = useRecoilValue(columnSelector(1));
  const column3 = useRecoilValue(columnSelector(2));
  const column4 = useRecoilValue(columnSelector(3));
  const column5 = useRecoilValue(columnSelector(4));

  const columns = [
    column1,
    column2,
    column3,
    column4,
    column5,
  ];

  return (
    <AttemptWrapper display="flex" justifyContent="center">
      {columns.map((c) => (
        <Box p={1} key={c.columnIndex}>
          <TileBox $firstMatch $match>
            <TileValue>{c.value}</TileValue>
            <TileLetter>{c.letter}</TileLetter>
          </TileBox>
          <Box>
            <ProductLabel>
              <strong>
                {LETTER_VALUES[c.letter]}
                {' pts.'}
              </strong>
              <br />
              𝗑
              {getMultiplierFromRow(c.rowIndex)}
            </ProductLabel>
          </Box>
        </Box>
      ))}
    </AttemptWrapper>
  );
};

export { AttemptTiles };
