import { useCallback, useEffect } from 'react';

interface UseKeyboardTypingOptions {
  disabled: boolean;
  onEnterPress: () => void;
  onKeyPress: (key: string) => void;
  onDeletePress: () => void;
}

type UseKeyboardTyping = (options: UseKeyboardTypingOptions) => void;

const useKeyboardTyping: UseKeyboardTyping = ({
  disabled,
  onEnterPress,
  onKeyPress,
  onDeletePress,
}) => {
  const handleKeydown = useCallback((event) => {
    if (!disabled) {
      switch (event.keyCode) {
        case 8: return onDeletePress();
        case 13: return onEnterPress();
        case 65: return onKeyPress('A');
        case 66: return onKeyPress('B');
        case 67: return onKeyPress('C');
        case 68: return onKeyPress('D');
        case 69: return onKeyPress('E');
        case 70: return onKeyPress('F');
        case 71: return onKeyPress('G');
        case 72: return onKeyPress('H');
        case 73: return onKeyPress('I');
        case 74: return onKeyPress('J');
        case 75: return onKeyPress('K');
        case 76: return onKeyPress('L');
        case 77: return onKeyPress('M');
        case 78: return onKeyPress('N');
        case 79: return onKeyPress('O');
        case 80: return onKeyPress('P');
        case 81: return onKeyPress('Q');
        case 82: return onKeyPress('R');
        case 83: return onKeyPress('S');
        case 84: return onKeyPress('T');
        case 85: return onKeyPress('U');
        case 86: return onKeyPress('V');
        case 87: return onKeyPress('W');
        case 88: return onKeyPress('X');
        case 89: return onKeyPress('Y');
        case 90: return onKeyPress('Z');
      }
    }
  }, [disabled, onDeletePress, onEnterPress, onKeyPress]);

  return useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [handleKeydown]);
};

export { useKeyboardTyping };
