import { STAT_BUCKETS } from '../constants/statBuckets';
import type { StatBucket } from '../constants/statBuckets';

type GetBucketFromScore = (totalScore: number, possibleScore: number) => StatBucket | null;

const getBucketFromScore: GetBucketFromScore = (totalScore, possibleScore) => {
  const percentage = Math.floor((totalScore / possibleScore) * 100);

  return STAT_BUCKETS.find((b) => (
    percentage >= b.min && percentage <= b.max
  )) || null;
};

export { getBucketFromScore };
