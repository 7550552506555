import { selector } from 'recoil';

import { WORDS } from '../constants/words';
import { getTileValue } from '../helpers/getTileValue';
import { gameNumberAtom } from './gameNumber';

const todaysWordSelector = selector({
  key: 'todaysWord',
  get: ({ get }) => WORDS[get(gameNumberAtom)],
})

const todaysPossiblePointsSelector = selector({
  key: 'todaysPossiblePoints',
  get: ({ get }) => (
    get(todaysWordSelector)
      .split('')
      .map((letter) => getTileValue({ letter, rowIndex: 0 }))
      .reduce((acc, cur) => acc + cur, 0)
  )
})

export {
  todaysWordSelector,
  todaysPossiblePointsSelector,
};
