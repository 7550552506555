import { atomFamily, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { gameNumberAtom } from './gameNumber';

const { persistAtom } = recoilPersist();

const attemptsAtom = atomFamily<string[], number>({
  key: 'attempts',
  default: [],
  effects: [persistAtom],
});

const totalAttemptsSelector = selector<number>({
  key: 'totalAttempts',
  get: ({ get }) => get(attemptsAtom(get(gameNumberAtom))).length,
});

const lastAttemptSelector = selector({
  key: 'lastAttempt',
  get: ({ get }) => {
    const attempts = get(attemptsAtom(get(gameNumberAtom)));

    return attempts[attempts.length - 1] || '';
  },
});

const usedLettersSelector = selector<string[]>({
  key: 'usedLetters',
  get: ({ get }) => (
    Object.keys(
      get(attemptsAtom(get(gameNumberAtom))).join('').split('').reduce((acc, cur) => ({
        ...acc,
        [cur]: cur,
      }), {})
    )
  ),
});

export {
  attemptsAtom,
  lastAttemptSelector,
  totalAttemptsSelector,
  usedLettersSelector,
};
