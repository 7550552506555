import { Box } from '@mui/material';

import { Tile } from './Tile';
import { COLUMN_COUNT } from '../constants/limits';

interface TilesInputRowProps {
  inputLetters: string[];
  rowIndex: number;
}

const TilesInputRow = ({
  inputLetters,
  rowIndex,
}: TilesInputRowProps): JSX.Element => (
  <Box display="flex" justifyContent="center">
    {Array(COLUMN_COUNT).fill(null).map((_, i) => (
        <Box key={i} p={0.5} flexShrink={0}>
          <Tile
            letter={inputLetters[i] || ''}
            rowIndex={rowIndex}
            columnIndex={i}
          />
        </Box>
      )
    )}
  </Box>
);

export { TilesInputRow };
