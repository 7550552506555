import type { CompletedGame } from '../state/completedGames';

const getMaxStreak = (games: CompletedGame[]): number => {
  const streaks: number[] = [];
  let currentStreak = 0;

  for (let i = 0; i < games.length; i += 1) {
    const game = games[i];

    if (game.won) {
      currentStreak += 1;
    }

    if (!game.won || i === (games.length - 1)) {
      streaks.push(currentStreak);
      currentStreak = 0;
    }
  }

  return Math.max(...streaks);
};

export { getMaxStreak };
