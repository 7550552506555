import { Box,Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { todaysWordSelector, todaysPossiblePointsSelector } from '../state/todaysWord';
import { currentScoreSelector, hasLostSelector } from '../state/score';

const ScoreTypography = styled(Typography)`
  font-size: 24px;
  line-height: 1.25;
  text-align: center;
`;

const StatsScore = (): JSX.Element => {
  const todaysPossiblePoints = useRecoilValue(todaysPossiblePointsSelector);
  const todaysWord = useRecoilValue(todaysWordSelector);
  const hasLost = useRecoilValue(hasLostSelector);
  const score = useRecoilValue(currentScoreSelector);
  const currentScore = hasLost ? 0 : score;
  const percentage = Math.floor((currentScore / todaysPossiblePoints) * 100);

  return (
    <Box px={2} pt={2} pb={1}>
      <ScoreTypography>
        {hasLost ? (
          <>
            You have lost!
            <br />
            The word was "{todaysWord}"
          </>
        ) : (
          <>
            You scored
            {' '}
            <strong>{currentScore}</strong>
            {' '}
            ({percentage}%) out of
            {' '}
            <strong>{todaysPossiblePoints}</strong>
            {' '}
            possible points.
          </>
        )}
      </ScoreTypography>
    </Box>
  );
};

export { StatsScore };
