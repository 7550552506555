import { selector, selectorFamily } from 'recoil';

import { todaysWordSelector } from './todaysWord';
import { attemptsAtom, usedLettersSelector } from './attempts';
import { gameNumberAtom } from './gameNumber';
import type { TileState } from '../constants/tileStates';
import {
  FIRST_MATCH,
  MATCH,
  CLOSE,
  NO_MATCH,
} from '../constants/tileStates';

const closeLettersSelector = selector<string[]>({
  key: 'closeLetters',
  get: ({ get }) => {
    const todaysWordLetters = get(todaysWordSelector).split('');

    return get(usedLettersSelector).filter((usedLetter) => (
      todaysWordLetters.includes(usedLetter)
    ));
  },
});

const matchedLettersSelector = selector<string[]>({
  key: 'matchedLetters',
  get: ({ get }) => {
    const todaysWordLetters = get(todaysWordSelector).split('');
    const gameNumber = get(gameNumberAtom);
    const attempts = get(attemptsAtom(gameNumber)).map((attempt) => attempt.split(''));

    return todaysWordLetters.filter((letter, i) => (
      attempts.map((attempt) => attempt[i]).some((attempt) => attempt === letter)
    ));
  },
});

const matchesSelector = selector({
  key: 'matches',
  get: ({ get }) => {
    const todaysWord = get(todaysWordSelector);
    const todaysWordLetters = todaysWord.split('');
    const gameNumber = get(gameNumberAtom);
    const attempts = get(attemptsAtom(gameNumber)).map((attempt) => attempt.split(''));

    return attempts.map((attempt, rowI) => (
      attempt.map((letter, colI) => {
        const todayLetter = todaysWordLetters[colI];
        const allLetterAttempts = attempts.map((a) => a[colI]);
        const firstAttemptIndex = allLetterAttempts.findIndex((l) => l === todayLetter);
        const attemptLetterMatches = attempt.map((a, i) => a === letter && todaysWordLetters[i] === letter);
        const inWord = todaysWordLetters.map((l) => l === letter).filter((l) => !!l);
        const match = attemptLetterMatches.filter((l) => !!l);
        const alreadyClose = attempt.slice(0, colI).map((a, i) => (
          a === letter
            && letter !== todaysWordLetters[i]
            && todaysWordLetters.includes(letter)
        )).filter((l) => !!l);

        const isClose = (
          todaysWordLetters.includes(letter)
            && inWord.length > match.length
            && inWord.length > alreadyClose.length
        );

        let matchOutput: TileState = NO_MATCH;
        
        if (todayLetter === letter && firstAttemptIndex === rowI) {
          matchOutput = FIRST_MATCH;
        } else if (todayLetter === letter) {
          matchOutput = MATCH;
        } else if (isClose) {
          matchOutput = CLOSE;
        }

        return matchOutput;
      })
    ));
  },
});

interface TitleMatchSelectorReturn {
  isFirstMatched: boolean;
  isMatched: boolean;
  isClose: boolean;
}

const tileMatchSelector = selectorFamily<TitleMatchSelectorReturn, [number, number]>({
  key: 'tileMatchSelector',
  get: ([rowIndex, columnIndex]) => ({ get }) => {
    const row = (get(matchesSelector) || [])[rowIndex];
    const column = (row || [])[columnIndex];

    return {
      isFirstMatched: column === FIRST_MATCH,
      isMatched: column >= MATCH,
      isClose: column === CLOSE,
    };
  },
});

export {
  closeLettersSelector,
  matchesSelector,
  matchedLettersSelector,
  tileMatchSelector,
  todaysWordSelector,
};
