import { atom } from 'recoil';

interface Feedback {
  mood: string | null;
  message: string | null;
}

const feedbackAtom = atom<Feedback>({
  key: 'feedback',
  default: {
    mood: null,
    message: null,
  },
});

export type { Feedback };
export { feedbackAtom };
