interface StatBucket {
  max: number;
  min: number;
  label: string;
}

const STAT_BUCKETS: StatBucket[] = [
  { max: 100, min: 80, label: '80-100%' },
  { max: 79, min: 60, label: '60-79%' },
  { max: 59, min: 40, label: '40-59%' },
  { max: 39, min: 20, label: '20-39%' },
  { max: 19, min: 0, label: '0-19%' },
];

export type { StatBucket };
export { STAT_BUCKETS };
