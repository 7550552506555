import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Box,
  Dialog,
  Grid,
  Typography,
} from '@mui/material';

import { openDialogSelector, dialogAtom } from '../state/dialog';
import { hasWonSelector, gameIsFinishedSelector } from '../state/score';
import { playedGamesSelector } from '../state/completedGames';
import { StatsCountdown } from './StatsCountdown';
import { StatsSummary } from './StatsSummary';
import { StatsShare } from './StatsShare';
import { StatsScore } from './StatsScore';
import { StatsGraph } from './StatsGraph';
import { AttemptTiles } from './AttemptTiles';
import { DebugMenu } from './DebugMenu';

interface StatsDialogProps {
  debugMode?: boolean;
}

const StatsDialog = ({
  debugMode = false,
}: StatsDialogProps): JSX.Element => {
  const open = useRecoilValue(openDialogSelector('stats'));
  const hasWon = useRecoilValue(hasWonSelector);
  const setDialog = useSetRecoilState(dialogAtom);
  const playedGames = useRecoilValue(playedGamesSelector);
  const gameIsFinished = useRecoilValue(gameIsFinishedSelector);

  return (
    <Dialog open={open} onClose={() => setDialog(null)}>
      {gameIsFinished && (
        <StatsScore />
      )}
      {hasWon && (
        <AttemptTiles />
      )}
      {gameIsFinished && (
        <Box p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flexGrow="1"
              >
                <StatsCountdown />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flexGrow="1"
              >
                <StatsShare />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      {debugMode && (
        <DebugMenu />
      )}
      {playedGames > 0 ? (
        <>
          <StatsGraph />
          <StatsSummary />
        </>
      ) : (
        <Box p={2}>
          <Typography align="center">
            No stats are available until games have been played
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export { StatsDialog };
