import { selector, selectorFamily } from 'recoil';

import { getTileValue } from '../helpers/getTileValue';
import { attemptsAtom, lastAttemptSelector, totalAttemptsSelector } from './attempts';
import { matchesSelector } from './match';
import { todaysPossiblePointsSelector, todaysWordSelector } from './todaysWord';
import { FIRST_MATCH } from '../constants/tileStates';
import { ROW_COUNT } from '../constants/limits';
import { getBucketFromScore } from '../helpers/getBucketFromScore';
import { gameNumberAtom } from './gameNumber';

const rowScoreSelector = selectorFamily<number, number>({
  key: 'rowScore',
  get: (rowIndex) => ({ get }) => {
    const gameNumber = get(gameNumberAtom);
    const matchRow = get(matchesSelector)[rowIndex];

    return matchRow.map((c, columnIndex) => (
      c === FIRST_MATCH ? getTileValue({
        letter: get(attemptsAtom(gameNumber))[rowIndex][columnIndex],
        rowIndex: rowIndex,
      }) : 0
    )).reduce((acc, cur) => (acc + cur), 0);
  },
});

interface ColumnScore {
  columnIndex: number;
  rowIndex: number;
  value: number;
  letter: string;
}

const columnSelector = selectorFamily<ColumnScore, number>({
  key: 'columnScore',
  get: (columnIndex) => ({ get }) => {
    const columnMatches = get(matchesSelector).map((m) => m[columnIndex]);
    const letter = get(lastAttemptSelector).split('')[columnIndex];
    const rowIndex = columnMatches.findIndex((c) => c === FIRST_MATCH);
    const value = getTileValue({ letter, rowIndex });

    return {
      columnIndex,
      rowIndex,
      value,
      letter,
    };
  },
});

const currentScoreSelector = selector({
  key: 'currentScore',
  get: ({ get }) => {
    const gameNumber = get(gameNumberAtom);
    const countableScores = get(matchesSelector).map((row, rowIndex) => (
      row.map((c, columnIndex) => (
        c === FIRST_MATCH ? getTileValue({
          letter: get(attemptsAtom(gameNumber))[rowIndex][columnIndex],
          rowIndex: rowIndex,
        }) : 0
      ))
    ));

    return countableScores.flat().reduce((acc, cur) => (acc + cur), 0);
  },
});

const hasWonSelector = selector({
  key: 'hasWon',
  get: ({ get }) => {
    const todaysWord = get(todaysWordSelector);
    const lastAttempt = get(lastAttemptSelector);

    return lastAttempt === todaysWord;
  },
});

const hasLostSelector = selector({
  key: 'hasLost',
  get: ({ get }) => {
    const totalAttempts = get(totalAttemptsSelector);
    const todaysWord = get(todaysWordSelector);
    const lastAttempt = get(lastAttemptSelector);

    return totalAttempts === ROW_COUNT && lastAttempt !== todaysWord;
  },
});

const gameIsFinishedSelector = selector({
  key: 'gameIsFinished',
  get: ({ get }) => get(hasWonSelector) || get(hasLostSelector),
});

const scoreBucketSelector = selector({
  key: 'scoreBucket',
  get: ({ get }) => (
    getBucketFromScore(
      get(currentScoreSelector),
      get(todaysPossiblePointsSelector)
    )
  )
});

export {
  columnSelector,
  currentScoreSelector,
  gameIsFinishedSelector,
  hasLostSelector,
  hasWonSelector,
  rowScoreSelector,
  scoreBucketSelector,
};
