import { Box, Button } from '@mui/material';
import { Backspace, Done } from '@mui/icons-material';
import styled from 'styled-components';

import { KEYS } from '../constants/keys';
import { COLORS } from '../constants/theme';

interface KeyStyleProps {
  $action?: boolean;
  $close?: boolean;
  $matched?: boolean;
  $used?: boolean;
}

const Key = styled(Button)<KeyStyleProps>`
  padding: 0;
  min-width: 0;
  width: calc(9vw - 4px);
  max-width: 50px;
  height: calc(15vw - 4px);
  color: ${COLORS.offWhite};
  
  ${({ $action }) => !$action ? `
    &,
    &:hover {
      background-color: ${COLORS.gray};
    }
  ` : `
    width: calc(13vw - 4px);
  `}

  &,
  svg {
    font-size: 13px;
  }

  @media (max-width: 374px) {
    width: 23px;
    height: 40px;
    
    &,
    svg {
      font-size: 11px;
    }
  }

  @media (min-width: 600px) {
    height: 50px;
  }

  ${({ $used }) => !!$used && `
    &,
    &:hover {
      background-color: #222;
    }
  `}

  ${({ $close }) => !!$close && `
    &,
    &:hover {
      background-color: ${COLORS.orange};
    }
  `}

  ${({ $matched }) => !!$matched && `
    &,
    &:hover {
      background-color: ${COLORS.green};
    }
  `}
`;

interface KeyboardProps {
  canDelete: boolean;
  canEnter: boolean;
  closeLetters: string[];
  disabled: boolean;
  matchedLetters: string[];
  onEnterPress: () => void;
  onKeyPress: (key: string) => void;
  onDeletePress: () => void;
  usedLetters: string[];
}

const Keyboard = ({
  canDelete,
  canEnter,
  closeLetters,
  disabled,
  matchedLetters,
  onKeyPress,
  onDeletePress,
  onEnterPress,
  usedLetters,
}: KeyboardProps): JSX.Element => (
  <Box pt={1}>
    {KEYS.map((row, i) => (
      <Box
        key={i}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {i === 2 && (
          <Box p={0.5}>
            <Key
              variant="contained"
              color="success"
              onClick={onEnterPress}
              disabled={!canEnter || disabled}
              $action
              disableRipple
            >
              <Done />
            </Key>
          </Box>
        )}
        {row.map((key) => (
          <Box key={key} p={0.5}>
            <Key
              variant="contained"
              onClick={() => onKeyPress(key)}
              $close={closeLetters.includes(key)}
              $matched={matchedLetters.includes(key)}
              $used={usedLetters.includes(key)}
              disabled={disabled}
              disableRipple
            >
              {key}
            </Key>
          </Box>
        ))}
        {i === 2 && (
          <Box p={0.5}>
            <Key
              variant="contained"
              color="error"
              onClick={onDeletePress}
              disabled={!canDelete || disabled}
              $action
              disableRipple
            >
              <Backspace />
            </Key>
          </Box>
        )}
      </Box>
    ))}
  </Box>
);

export { Keyboard };
