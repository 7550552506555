const FIRST_MATCH = 3 as const;
const MATCH = 2 as const;
const CLOSE = 1 as const;
const NO_MATCH = 0 as const;

type TileState = typeof FIRST_MATCH | typeof MATCH | typeof CLOSE | typeof NO_MATCH;

export type { TileState };
export {
  FIRST_MATCH,
  MATCH,
  CLOSE,
  NO_MATCH,
};
