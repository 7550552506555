import { atom, selector } from 'recoil';
import { takeRightWhile } from 'lodash';
import { recoilPersist } from 'recoil-persist';

import { STAT_BUCKETS } from '../constants/statBuckets';
import { getMaxStreak } from '../helpers/getMaxStreak';

interface CompletedGame {
  game: number;
  won: boolean;
  guessIndex: number;
  totalScore: number;
  possibleScore: number;
}

const { persistAtom } = recoilPersist();

const completedGamesAtom = atom<CompletedGame[]>({
  key: 'completedGames',
  default: [],
  effects: [persistAtom],
});

const statsByBucketSelector = selector({
  key: 'statsByBucket',
  get: ({ get }) => {
    const games = get(completedGamesAtom);
    const buckets = STAT_BUCKETS.map((b) => ({
      ...b,
      count: games.filter((g) => {
        const percentage = Math.floor((g.totalScore / g.possibleScore) * 100);
        return g.won && (percentage >= b.min && percentage <= b.max);
      }).length,
    }));

    return buckets;
  },
});

const playedGamesSelector = selector({
  key: 'playedGames',
  get: ({ get }) => get(completedGamesAtom).length,
});

const wonPercentageSelector = selector({
  key: 'winPercentage',
  get: ({ get }) => {
    const wonCount = get(completedGamesAtom).filter((g) => g.won).length;
    const totalCount = get(playedGamesSelector);

    return Math.floor((wonCount / totalCount) * 100) || 0;
  },
});

const totalPointsSelector = selector({
  key: 'totalPoints',
  get: ({ get }) => (
    get(completedGamesAtom).map((g) => g.totalScore).reduce((acc, cur) => (
      acc + cur
    ), 0)
  )
});

const possiblePointsSelector = selector({
  key: 'possiblePoints',
  get: ({ get }) => (
    get(completedGamesAtom).map((g) => g.possibleScore).reduce((acc, cur) => (
      acc + cur
    ), 0)
  )
});

const averagePointsPercentageSelector = selector({
  key: 'averagePointsPercentage',
  get: ({ get }) => {
    const possiblePoints = get(possiblePointsSelector);
    const totalPoints = get(totalPointsSelector);

    return Math.floor((totalPoints / possiblePoints) * 100) || 0;
  },
});

const currentStreakSelector = selector({
  key: 'currentStreak',
  get: ({ get }) => {
    const games = get(completedGamesAtom);
    const streak = takeRightWhile(games, (g) => g.won);

    return streak.length || 0;
  },
});

const maxStreakSelector = selector({
  key: 'maxStreak',
  get: ({ get }) => (
    getMaxStreak(get(completedGamesAtom))
  ),
});

export type { CompletedGame };
export {
  averagePointsPercentageSelector,
  completedGamesAtom,
  currentStreakSelector,
  maxStreakSelector,
  possiblePointsSelector,
  playedGamesSelector,
  statsByBucketSelector,
  totalPointsSelector,
  wonPercentageSelector,
};
