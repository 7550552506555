import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import styled from 'styled-components';
import type { LabelProps } from 'recharts';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { COLORS } from '../constants/theme';
import { statsByBucketSelector } from '../state/completedGames';
import { scoreBucketSelector } from '../state/score';
import { hasWonSelector } from '../state/score';

const ChartWrapper = styled(Box)`
  position: relative;
  
  text {
    fill: #fff;
  }
`;

const renderCustomizedLabel = ({
  x,
  y,
  width,
  height,
  value,
}: LabelProps): JSX.Element => (
  <text
    x={((x as number) + (width as number) + 12.5) || 0}
    y={((y as number) + (height as number) - 5) || 0}
    fontSize={12}
    textAnchor="end"
  >
    {value}
  </text>
);

const StatsGraph = (): JSX.Element => {
  const buckets = useRecoilValue(statsByBucketSelector);
  const hasWon = useRecoilValue(hasWonSelector);
  const bucket = useRecoilValue(scoreBucketSelector);

  return (
    <ChartWrapper pt={1} px={2}>
      <ResponsiveContainer width="100%" height={100}>
        <BarChart
          data={buckets}
          width={300}
          height={150}
          layout="vertical"
          maxBarSize={80}
          barSize={80}
          margin={{ right: 40 }}
        >
          <XAxis type="number" hide />
          <YAxis
            dataKey="label"
            fontSize="9px"
            type="category"
            interval={0}
          />
          <Bar
            dataKey="count"
            fill={COLORS.gray}
          >
            {buckets.map((b) => (
              <Cell
                key={b.max}
                fill={hasWon && b.max === bucket?.max ? COLORS.green : COLORS.darkGray}
              />
            ))}
            <LabelList
              dataKey="count"
              content={renderCustomizedLabel}
              position="insideRight"
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

export { StatsGraph };
