import {
  FIRST_MATCH,
  MATCH,
  CLOSE,
  NO_MATCH,
} from './tileStates';

const TILE_EMOJIS = {
  [FIRST_MATCH]: '❇️',
  [MATCH]: '🟩',
  [CLOSE]: '🟨',
  [NO_MATCH]: '⬛️',
} as const;

export { TILE_EMOJIS };
