import { atom, selector, selectorFamily } from 'recoil';

import { getTileValue } from '../helpers/getTileValue';

const keyboardInputAtom = atom({
  key: 'keyboardInput',
  default: '',
});

const inputLettersSelector = selector({
  key: 'inputLetters',
  get: ({ get }) => get(keyboardInputAtom).split(''),
});

const inputTotalSelector = selectorFamily<number, number>({
  key: 'inputTotalSelector',
  get: (rowIndex) => ({ get }) => (
    get(inputLettersSelector).reduce((acc, letter) => (
      acc + getTileValue({ letter, rowIndex })
    ), 0)
  ),
});

const cursorColumnSelector = selector({
  key: 'cursorColumn',
  get: ({ get }) => get(keyboardInputAtom).length,
});

export {
  cursorColumnSelector,
  keyboardInputAtom,
  inputLettersSelector,
  inputTotalSelector,
};
