import { useRecoilValue } from 'recoil';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { currentScoreSelector, hasLostSelector } from '../state/score';
import { feedbackAtom } from '../state/feedback';
import { COLORS } from '../constants/theme';

interface FeedbackBoxStyleProps {
  $mood: string | null;
}

const FeedbackBox = styled(Box)<FeedbackBoxStyleProps>`
  background-color: ${COLORS.pink};
  color: ${COLORS.offWhite};
  height: 37px;
  transition: .25s background-color ease-in-out;

  ${({ $mood }) => $mood === 'positive' && `
    background-color: ${COLORS.green};
  `}

  ${({ $mood }) => $mood === 'negative' && `
    background-color: ${COLORS.red};
  `}
`;

const FeedbackTypography = styled(Typography)`
  font-size: 14px;
  letter-spacing: 1px;
`;

const Feedback = (): JSX.Element => {
  const { message, mood } = useRecoilValue(feedbackAtom);
  const score = useRecoilValue(currentScoreSelector);
  const hasLost = useRecoilValue(hasLostSelector);
  const currentScore = hasLost ? 0 : score;

  return (
    <FeedbackBox py={1} $mood={mood}>
      <FeedbackTypography align="center">
        {(message && mood) ? (
          <strong>
            {message}
          </strong>
        ) : (
          <>
            Your score:
            {' '}
            <strong>
              {currentScore}
              {' '}
              points
            </strong>
          </>
        )}
      </FeedbackTypography>
    </FeedbackBox>
  );
};

export { Feedback };
