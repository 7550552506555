import { LETTER_VALUES } from '../constants/letterValues';
import { getMultiplierFromRow } from './getMultiplierFromRow';

interface GetTileValueOptions {
  letter: string;
  rowIndex: number;
}

type GetTileValue = (options: GetTileValueOptions) => number;

const getTileValue: GetTileValue = ({
  letter,
  rowIndex,
}) => (
  (LETTER_VALUES[letter] || 0) * getMultiplierFromRow(rowIndex)
);

export { getTileValue };
