import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { feedbackAtom } from '../state/feedback';
import type { Feedback } from '../state/feedback';
import { FEEDBACK_TIMEOUT } from '../constants/limits';

const useFeedback = () => {
  const setFeedback = useSetRecoilState(feedbackAtom);

  const launchFeedback = useCallback((fb: Feedback) => {
    setFeedback(fb);
    setTimeout(() => setFeedback({
      message: null,
      mood: null,
    }), FEEDBACK_TIMEOUT);
  }, [setFeedback]);

  return { launchFeedback };
};

export { useFeedback };
