import { useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { produce } from 'immer';

import { currentScoreSelector, hasWonSelector, gameIsFinishedSelector } from '../state/score';
import { attemptsAtom } from '../state/attempts';
import { gameNumberAtom } from '../state/gameNumber';
import { completedGamesAtom } from '../state/completedGames';
import { todaysPossiblePointsSelector } from '../state/todaysWord';

const useCompleteGame = () => {
  const [completedGames, setCompletedGames] = useRecoilState(completedGamesAtom);
  const gameNumber = useRecoilValue(gameNumberAtom);
  const currentScore = useRecoilValue(currentScoreSelector);
  const todaysPossiblePoints = useRecoilValue(todaysPossiblePointsSelector);
  const attempts = useRecoilValue(attemptsAtom(gameNumber));
  const won = useRecoilValue(hasWonSelector);
  const gameIsFinished = useRecoilValue(gameIsFinishedSelector);

  return useEffect(() => {
    const alreadyHasGame = completedGames.findIndex((g) => g.game === gameNumber) > -1;
    if (gameIsFinished && !alreadyHasGame) {
      const totalScore = won ? currentScore : 0;
      const guessIndex = attempts.length;

      gtag('event', 'level_end', {
        level_name: `Game ${gameNumber}`,
        success: won,
      });

      gtag('event', 'earn_virtual_currency', {
        virtual_currency_name: 'points',
        value: totalScore,
      });

      gtag('event', 'guess_success_index', {
        value: guessIndex,
      });

      setCompletedGames(produce(completedGames, (newCompletedGames) => {
        newCompletedGames.push({
          game: gameNumber,
          won,
          guessIndex,
          possibleScore: todaysPossiblePoints,
          totalScore,
        });
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameIsFinished]);
};

export { useCompleteGame };
