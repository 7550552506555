import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';

import { openDialogSelector, dialogAtom } from '../state/dialog';

const HelpDialog = (): JSX.Element => {
  const open = useRecoilValue(openDialogSelector('help'));
  const setDialog = useSetRecoilState(dialogAtom);

  const close = useCallback(() => {
    gtag('event', 'tutorial_complete');
    setDialog(null);
  }, [setDialog]);

  useEffect(() => {
    if (open) {
      gtag('event', 'tutorial_begin');
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>
        Welcome to Worbble!
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          Worbble is a daily score-based word game.
          {' '}
          The goal is to guess the word in the smallest number of turns possible.
        </Typography>
        <br />
        <Typography variant="body2">
          Each letter has a tile value (similar to a famous board game), but is multiplied inversely by the number of attempts taken to find the letter.
        </Typography>
        <br />
        <Typography variant="body2">
          For instance, a letter found on the first turn is worth 6 times its point value, while a letter found on the last turn is worth only its tile value.
        </Typography>
        <br />
        <Typography variant="body2">
          Your results are tracked, and a new Worbble word can be played every day starting at midnight!
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export { HelpDialog };
