import { useRecoilValue } from 'recoil';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import {
  averagePointsPercentageSelector,
  currentStreakSelector,
  maxStreakSelector,
  playedGamesSelector,
  totalPointsSelector,
  wonPercentageSelector,
} from '../state/completedGames';


const SummaryBox = styled(Box)`
  width: 100%;
  max-width: 80px;
`;

const SummaryLabel = styled(Typography)`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
`;

const SummaryValue = styled(Typography)`
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 4px;
`;

const StatsSummary = () => {
  const played = useRecoilValue(playedGamesSelector);
  const wonPercentage = useRecoilValue(wonPercentageSelector);
  const totalPoints = useRecoilValue(totalPointsSelector);
  const averagePointsPercentage = useRecoilValue(averagePointsPercentageSelector);
  const currentStreak = useRecoilValue(currentStreakSelector);
  const maxStreak = useRecoilValue(maxStreakSelector);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      flexWrap="wrap"
      p={2}
      pt={1}
    >
      <SummaryBox p={1}>
        <SummaryValue>
          {played}
        </SummaryValue>
        <SummaryLabel>
          Played
        </SummaryLabel>
      </SummaryBox>
      <SummaryBox p={1}>
        <SummaryValue>
          {currentStreak}
        </SummaryValue>
        <SummaryLabel>
          Current Streak
        </SummaryLabel>
      </SummaryBox>
      <SummaryBox p={1}>
        <SummaryValue>
          {maxStreak || 0}
        </SummaryValue>
        <SummaryLabel>
          Max Streak
        </SummaryLabel>
      </SummaryBox>
      <SummaryBox p={1}>
        <SummaryValue>
          {wonPercentage}%
        </SummaryValue>
        <SummaryLabel>
          Win %
        </SummaryLabel>
      </SummaryBox>
      <SummaryBox p={1}>
        <SummaryValue>
          {totalPoints}
        </SummaryValue>
        <SummaryLabel>
          All-Time Points
        </SummaryLabel>
      </SummaryBox>
      <SummaryBox p={1}>
        <SummaryValue>
          {averagePointsPercentage}%
        </SummaryValue>
        <SummaryLabel>
          All-Time Average %
        </SummaryLabel>
      </SummaryBox>
    </Box>
  );
};

export { StatsSummary };
