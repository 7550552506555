import { createTheme } from '@mui/material/styles';
import type { ThemeOptions } from '@mui/material/styles';

const COLORS = {
  darkGray: '#2d2a2e',
  gray: '#403e41',
  offWhite: '#fcfcfa',
  yellow: '#ffbe00',
  green: '#00b500',
  purple: '#c91cff',
  orange: '#ff8200',
  pink: '#ff6db6',
  red: '#ed2b15',
} as const;

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: COLORS.pink,
    },
    background: {
       default: COLORS.darkGray,
    },
  },
  typography: {
    fontFamily: 'Archivo, sans-serif',
  },
};

const theme = createTheme(themeOptions);

export {
  COLORS,
  themeOptions,
  theme,
};
