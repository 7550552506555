import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { rgba } from 'polished';
import { BarChart, HelpOutline } from '@mui/icons-material';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from '@mui/material';

import { COLORS } from '../constants/theme';
import { dialogAtom } from '../state/dialog';

const StyledAppBar = styled(AppBar)`
  &,
  .MuiToolbar-rootStyled {
    height: 56px !important;
  }
`;

const HeaderTypography = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;

  @media (max-width: 374px) {
    font-size: 9px;
  }

  > span {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 26px;
    height: 26px;
    border: 1px solid ${COLORS.gray};
    background-color: ${rgba(COLORS.green, 0.66)};

    @media (max-width: 374px) {
      width: 18px;
      height: 18px;
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      background-color: ${rgba(COLORS.orange, 0.66)};
    }
  }
`;

const Header = (): JSX.Element => {
  const setDialog = useSetRecoilState(dialogAtom);

  return (
    <StyledAppBar position="fixed">
      <Toolbar>
        <Box display="flex" justifyContent="flex-start" flexGrow={1}>
          <HeaderTypography variant="h1">
            <span>W</span>
            <span>o</span>
            <span>r</span>
            <span>b</span>
            <span>b</span>
            <span>l</span>
            <span>e</span>
          </HeaderTypography>
        </Box>
        <Box display="flex">
          <Box ml={1}>
            <IconButton onClick={() => setDialog('help')} tabIndex={-1}>
              <HelpOutline />
            </IconButton>
          </Box>
          <Box ml={1}>
            <IconButton onClick={() => setDialog('stats')} tabIndex={-1}>
              <BarChart />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </StyledAppBar>
  );
};

export { Header };
