import { selector } from 'recoil';

import { TILE_EMOJIS } from '../constants/tileEmojis';
import { COLUMN_COUNT, ROW_COUNT } from '../constants/limits';
import { getNumberEmojis } from '../helpers/getNumberEmojis';
import { matchesSelector } from './match';
import { currentScoreSelector, hasWonSelector, rowScoreSelector } from './score';
import { gameNumberAtom } from './gameNumber';

const shareOutputSelector = selector({
  key: 'shareOutput',
  get: ({ get }) => {
    const matches = get(matchesSelector);
    const score = get(currentScoreSelector);
    const hasWon = get(hasWonSelector);
    const gameNumber = get(gameNumberAtom) + 1;

    return [
      `Worbble ${gameNumber} ${matches.length}/${ROW_COUNT}`,
      ...matches.map((row, i) => ([
        row.map((column) => TILE_EMOJIS[column]).join(''),
        '➕',
        getNumberEmojis(get(rowScoreSelector(i))),
      ].join(''))),
      (
        hasWon
          ? `${Array(COLUMN_COUNT).fill('✅').join('')}➡️${getNumberEmojis(score)}`
          : `${Array(COLUMN_COUNT).fill('❌').join('')}➡️${getNumberEmojis(0)}`
      ),
      `https://worbblegame.com`,
    ].join('\n');
  }
});

export {
  currentScoreSelector,
  hasWonSelector,
  matchesSelector,
  shareOutputSelector,
};
