import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';

import { gameNumberAtom } from '../state/gameNumber';

const GameNumberTypography = styled(Typography)`
  position: absolute;
  top: 124px;
  right: -16px;
  font-size: 12px;
  line-height: 1;
  color: rgba(255,255,255,0.1);
  text-align: right;
  transform: rotate(-90deg);
  transform-origin: center center;
}
`;

const GameNumber = (): JSX.Element => {
  const gameNumber = useRecoilValue(gameNumberAtom);

  return (
    <GameNumberTypography variant="h2">
      Game #
      {(gameNumber + 1)}
    </GameNumberTypography>
  );
};

export { GameNumber };
