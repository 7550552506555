import { useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import { getTimeUntilNextGame } from '../helpers/getTimeUntilNextGame';

const TimeLeftLabel = styled(Typography)`
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  line-height: 1;
  margin-top: 4px;
`;

const TimeLeftValue = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  font-weight: 700;
  line-height: 1;
`;

const StatsCountdown = (): JSX.Element => {
  const [secondsLeft, setSecondsLeft] = useState(getTimeUntilNextGame());

  useEffect(() => {
    const update = setInterval(() => (
      setSecondsLeft(getTimeUntilNextGame())
    ), 1000);

    return () => {
      clearInterval(update);
    };
  }, []);

  useEffect(() => {
    if (secondsLeft === 0) {
      window.location.reload();
    }
  }, [secondsLeft]);

  const {
    minutesLeft,
    hoursLeft,
    displaySecondsLeft,
  } = useMemo(() => ({
    minutesLeft :`0${Math.floor(secondsLeft % 3600 / 60)}`.slice(-2),
    hoursLeft: `0${Math.floor(secondsLeft / 3600)}`.slice(-2),
    displaySecondsLeft: `0${secondsLeft % 60}`.slice(-2),
  }), [secondsLeft])

  return (
    <Box>
      <TimeLeftValue>
        {hoursLeft}
        :
        {minutesLeft}
        :
        {displaySecondsLeft}
      </TimeLeftValue>
      <TimeLeftLabel>
        until next Worbble
      </TimeLeftLabel>
    </Box>
  );
};

export { StatsCountdown };
